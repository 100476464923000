@font-face {
    font-family: "Roboto";
    src: local("Roboto-Medium"),
    url("./assets/Roboto/Roboto-Medium.ttf") format("truetype");
}

body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
}
